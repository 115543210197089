<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Cristian Darío Valencia Agudelo                                 ###### -->
<!-- ###### @date: Septiembre 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <section class="carta-containter">
      <section>
        <article v-if="!id">
          <v-container>
            <v-form ref="form" class="carta-file">
              <v-file-input
                v-model="selectedFile"
                label="Seleccionar Carta de agotado"
                accept=".pdf"
                prepend-icon="mdi-file-pdf"
                show-size
                outlined
                @change="handleFileUpload"
                required
              ></v-file-input>
  
              <v-btn
                :disabled="!selectedFile"
                color="primary"
                @click="submitFile"
              >
                Iniciar Carta
              </v-btn>
  
              <v-alert
                v-if="uploadStatus"
                type="success"
                dismissible
              >
                Archivo subido exitosamente.
              </v-alert>
            </v-form>
          </v-container>
        </article>
  
        <article class="carta" v-if="id">
          <section>
            <aside :style="{ flexBasis: asideWidth + '%' }">
              <h3>Cartas de agotados</h3>
              <div v-if="!loading">
      
                <article class="fecha">
                  <ul>
                    
                    <li>
                      <div>
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fecha"
                              label="Fecha Formulación"                          
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              required
                            ></v-text-field>
                          </template>
                          <v-date-picker 
                            v-model="fecha" 
                            @input="menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                    </li>
                  </ul>
                </article>
                <article class="entidad">
                  <ul>
                    
                    <li>
                      <div>
                        <v-text-field
                          v-model="laboratorio.nombre"
                          label="Laboratorio"
                          hide-details
                          @input="debounceFetchLaboratorio(index)"
                          @focus="debounceFetchLaboratorio2(index)"
                          required
                          >
                        </v-text-field>
                        <!-- <figure title="Laboratorio confirmado" v-if="laboratorio.id" class="laboratorio-check">
                          <v-icon class="me-2">check_circle</v-icon>
                        </figure>
                        <figure title="Medicamento sin confirmar" v-if="!laboratorio.id" class="laboratorio-alert">
                          <v-icon class="me-2">warning</v-icon>
                        </figure> -->
  
                        <section v-if="laboratorio.resultados && laboratorio.resultados.length>0">
                          <ul>
                            <li :title="resultado.nomProd" v-for="resultado in medicamento.resultados" :key="resultado.id" @click="selectMedicamento(index,resultado)">
                              <h4>{{ resultado.nombre }}</h4>
                            </li>                        
                          </ul>
                        </section>
                      </div>
                    </li>
                  </ul>
                </article>
                
                <article class="medicamentos">
                  <h4>Medicamentos</h4>
                  <ul>
                    <li v-for="(medicamento, index) in medicamentos" :key="index">
                      <div class="medicamento-nombre">
                        <v-text-field
                        v-model="medicamento.nombreProducto"
                        label="Nombre"
                        hide-details
                        @input="debounceFetchMedicamento(index)"
                        @focus="debounceFetchMedicamento2(index)"
                        required
                        ></v-text-field>
                        <figure title="Medicamento confirmado" v-if="medicamento.idProducto || medicamento.estado==4" class="medicamento-check">
                          <v-icon class="me-2">check_circle</v-icon>
                          <p> {{ medicamento.idProducto }}</p>
                        </figure>
                        <figure title="Medicamento sin confirmar" v-if="!medicamento.idProducto && medicamento.estado!=4" class="medicamento-alert">
                          <v-icon class="me-2">warning</v-icon>
                        </figure>
                        <section v-if="medicamento.resultados && medicamento.resultados.length>0">
                          <ul>
                            <li :title="resultado.nomProd" v-for="resultado in medicamento.resultados" :key="resultado.id" @click="selectMedicamento(index,resultado)">
                              <h4>{{ resultado.codProd }}</h4>
                              <h5>{{ resultado.nomProd }}</h5>
                              <h5>{{ resultado.pesBruts }}</h5>
                            </li>                        
                          </ul>
                        </section>
                      </div>
                      
                      <div>
                        <v-select
                          v-model="medicamento.activo"
                          :items="estados"
                          label="Estado"
                          item-text="label" 
                          item-value="value"
                        ></v-select>
                      </div>
    
                      <div>
                        <v-menu
                          ref="menu"
                          v-model="menuStates[index]"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="medicamento.fecha"
                              label="Fecha "
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              hide-details
                              :disabled="medicamento.estado!=2"
                              required
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker 
                            v-model="medicamento.fecha" 
                            @input="menuStates[index] = false"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                      <div>    
                        <figure v-if="estadoCarta!=2" @click="eliminarMedicamento(index)" class="ico-trash" title="Eliminar"><v-icon class="me-2">delete</v-icon></figure>
                      </div>
                    </li>
                    <li>
                      <div> 
                      </div> 
                      <div> 
                        <v-btn
                          class="mt-2"
                          color="success"
                          block
                          @click="addMedicamento"
                          v-if="estadoCarta!=2"
                        >
                          Nuevo
                          <figure class="ico-trash"><v-icon class="me-2">add</v-icon></figure>
                  
                        </v-btn>  
                      </div>
                    </li>
                  </ul>
                </article>
                <footer v-if="estadoCarta!=2">
                  <v-btn 
                    @click="actualizarCarta" variant="elevated" color="primary" :disabled="!todosLosMedicamentosTienenId()">
                    Actualizar carta
                  </v-btn>
                </footer>
              </div>
              <aside class="viewer-toggle">
                <div :class="[!vieweToggle ? 'active' : '']" @click="toggleViewer()">
                  <v-icon class="me-2">picture_as_pdf</v-icon>
                </div>
                <div :class="[vieweToggle ? 'active' : '']" @click="toggleViewer2()">
                  <v-icon class="me-2">description</v-icon>
                </div>
              </aside>
            </aside>
            <div class="divider" @mousedown="startDrag"></div>
            <article :style="{ flexBasis: articleWidth + '%' }">
              <section :class="[vieweToggle ? 'hide' : '']">            
                <div id="pdf-viewer"></div>     
                <div v-if="isDragging" id="pdf-viewer-cover"></div>                 
              </section>
              <section :class="[!vieweToggle ? 'hide' : '']">                        
                <!-- <div id="pdf-container"></div>                    -->
                <div ref="pdfContainer" class="pdf-container"></div>
              </section>
              <footer>
              </footer>
            </article>
          </section>
        </article>
      </section>

      <div class="loading" v-if="loading">
        <div class="loadingio-spinner-spinner-2by998twmg8"><div class="ldio-yzaezf3dcmj">
          <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
          </div></div>
          <h4>Extrayendo información de la carta de agotado...</h4>
      </div>
    </section>
</template>
  
  <!-- #################################################################################### -->
  <!-- ###### Sección de scripts                                                        ###### -->
  <!-- #################################################################################### -->
  <script>
  
  import { mapState } from "vuex";
  import PDFObject from 'pdfobject';
  import axios from 'axios';
  import moment from 'moment';
  import Swal from 'sweetalert2';

  import * as pdfjsLib from 'pdfjs-dist/build/pdf'; 
  import 'pdfjs-dist/web/pdf_viewer.css';
  pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

  export default {
    name: 'Index',    
    data: () => ({
      dev: false,
      tab: null,
      rolesKeycloak: null,
      codigosEmpresas: [],
      dataEmpresas: [],
      usuario: "",
      name: "",
      id: null,
      documento:"",
      nombre:"1",
      documentoMedico:"",
      nombreMedico:"",
      indicacion:"",
      observaciones:"",
      producto:"",
      cantidad:"",
      duracion:"",
      fecha:"",
      menu: false,
      asideWidth: 50,  
      articleWidth: 50, 
      isDragging: false,
      diagnosticos: [],
      menuStates: [],
      medicamentos: [],
      estados: [
        { label: 'Disponible', value: 1 },
        { label: 'No disponible', value: 2 },
        { label: 'Descontinuado', value: 3 },
        { label: 'No existe', value: 4 }
      ],
      laboratorio:{

      },
      estadoCarta: 1,
      searchQuery: '',
      timeout: null,
  
      loading: true,
      viewer: null,
      isDisabledMedico: false,
      file1: "1655146978601.pdf",
      file2: "1655148110018.pdf",
      url: "https://0qvhf3ep8b.execute-api.us-east-1.amazonaws.com/prod?function=View&filename=",
      urlBase64: "https://0qvhf3ep8b.execute-api.us-east-1.amazonaws.com/prod?function=Base64&filename=",
      
      // pdfUrl: "https://www.turnerlibros.com/wp-content/uploads/2021/02/ejemplo.pdf",
      // pdfUrl: "/ejemplo.pdf",
      // pdfUrl: "/5.pdf",            
      pdfUrl: "",            

      pdf: null,
      pageNum: 1,
      scale: 1.5,

      usuarioConfirmado: true,
      medicoConfirmado: false,
  
      imageBase64: "",

      vieweToggle: false,

      debounceTimer: null,

      selectedFile: null,
      base64File: null,
      uploadStatus: false
    
    }),
    created() {
      this.id = this.$route.params.id;
      const savedAsideWidth = localStorage.getItem('asideWidth');
      const savedArticleWidth = localStorage.getItem('articleWidth');
  
      if (savedAsideWidth && savedArticleWidth) {
        this.asideWidth = parseFloat(savedAsideWidth);
        this.articleWidth = parseFloat(savedArticleWidth);
      }
    },
    mounted() {
      this.usuario = this.auth.username;      
      this.name=this.$route.params.name;  
      
      this.medicamentos=[
      ];
      
      if(this.id){        
        this.getPdf();
      }else{
        this.loading=false;
      }
      
    },
    beforeDestroy() {
      if (this.viewer) {
        this.viewer.destroy();
      }
    },
    computed: {
      ...mapState(["auth", "enterprise"])
    },
  
    methods: {
      todosLosMedicamentosTienenId() {
        console.log(this.medicamentos);
        return this.medicamentos.every(medicamento => {
          console.log(medicamento.idProducto, medicamento.estado);
          return medicamento.idProducto !== undefined || medicamento.estado == 4;
        });
      },
      startDrag() {
        this.isDragging = true;
        document.addEventListener('mousemove', this.onDrag);
        document.addEventListener('mouseup', this.stopDrag);
      },
      onDrag(e) {
        if (this.isDragging) {
          const containerWidth = this.$el.offsetWidth; 
          const mouseX = e.clientX; 
          const newAsideWidth = ((mouseX / containerWidth) * 100) - 4;
          const newArticleWidth = 100 - newAsideWidth;
  
          if (newAsideWidth > 10 && newArticleWidth > 10) { 
            this.asideWidth = newAsideWidth;
            this.articleWidth = newArticleWidth;
          }
        }
      },
      stopDrag() {
        this.isDragging = false;
        document.removeEventListener('mousemove', this.onDrag);
        document.removeEventListener('mouseup', this.stopDrag);
  
        localStorage.setItem('asideWidth', this.asideWidth);
        localStorage.setItem('articleWidth', this.articleWidth);          
      },
  
      toggleViewer() {
        this.vieweToggle= false;
      },

      toggleViewer2() {
        this.vieweToggle= true;
      },

      eliminarMedicamento(index) {
        this.medicamentos.splice(index, 1); 
        this.menuStates.splice(index, 1);
      },

      addMedicamento() {
        this.medicamentos.push({
          nombre: "Nuevo Medicamento",
          fecha: null,
          estado: 1,
        })
      },
      
      addMedicamento2(word) {
        this.medicamentos.push({
          nombre: word,
          fecha: null,
          estado: 1,
        });
        
        // this.$http
        // .get("msa-administrador/api/afiliado/list?documento="+this.documento)
        // .then((result) => {
        //   console.log("usuario: ", result);
        //   if(result.data && result.data.nombreCompleto){
        //     this.nombre= result.data.nombreCompleto;
        //     this.estado= result.data.estadoAfiliado;
        //     if(result.data.estadoAfiliadoValue=="A"){
        //       this.usuarioConfirmado= true;
        //     }
        //   }
        // });
      },

      generateUUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        });
      },
      
      getPdf() {
        this.$http
          .get("msa-administration/api/carta/"+this.id, { headers: { 'Accept': 'application/json' } })
          .then((result) => {
            console.log("Carta Completa",result);
            this.pdfUrl = `data:application/pdf;base64,${result.data.pdf}`;
            this.embedPDF();
            if(result.data.estado==1){
              this.estadoCarta=1;
              this.loading=false;
              this.base64File=result.data.pdf;
              let data=JSON.parse(result.data.ocr);
              console.log(data);
              this.fecha= data.fecha;
              this.laboratorio={
                nombre: data.entidad.toUpperCase(),
              }

              this.medicamentos = data.medicamentos.map(medicamento => ({
                ...medicamento,
                estado: Number(medicamento.activo ?? 0),
                fecha: medicamento.fecha,
                nombreProducto: medicamento.nombre
              }));
              console.log("this.medicamentos",this.medicamentos);
              
              this.loadPdf();
            }
            else{
              this.estadoCarta=2;
              this.loadPdf2();
              this.loading=false;

              this.fecha= result.data.fechaEjecucion.substring(0, result.data.fechaEjecucion.indexOf("T"));
              this.laboratorio={
                nombre: result.data.laboratorio.toUpperCase(),
              }

              console.log("result.data.medicamentos", result.data.medicamentos);
              
              this.medicamentos = result.data.medicamentos.map(medicamento => ({
                ...medicamento,
                nombreProducto: (medicamento.estado==4)?medicamento.nombreProducto:((medicamento.producto[0].nomProd) || ''), 
                activo: medicamento.estado ?? 0,
                fecha: (medicamento.fecha && medicamento.fecha.substring)?medicamento.fecha.substring(0,medicamento.fecha.indexOf("T")):""
              }));

              console.log(this.medicamentos);
              
            }
          }) 
          .catch((error) => {
              console.log(error?.response?.data);
          });  
      },     
      embedPDF() {        
        PDFObject.embed(this.pdfUrl, "#pdf-viewer");
      },

      async loadPdf() {
        function base64ToUint8Array(base64) {
          const binaryString = atob(base64); 
          const len = binaryString.length;
          const bytes = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }
          return bytes;
        }
        // const url = this.pdfUrl; 
        const pdfContainer = this.$refs.pdfContainer;
                
        let base64Pdf = `${this.base64File}`;
        const data = base64ToUint8Array(base64Pdf);
        const pdf = await pdfjsLib.getDocument({ data, disableStream: true, maxImageSize: -1 }).promise;

        // const blob = new Blob([data], { type: 'application/pdf' });
        // const url2 = URL.createObjectURL(blob);
        // window.open(url2);

        const page = await pdf.getPage(1);
        const scale = 1.5;
        const viewport = page.getViewport({ scale });
        
        const textLayerDiv = document.createElement('div');
        textLayerDiv.className = 'textLayer';
        textLayerDiv.style.position = 'absolute';
        textLayerDiv.style.top = '0';
        textLayerDiv.style.left = '0';
        textLayerDiv.style.width = viewport.width + 'px';
        textLayerDiv.style.height = viewport.height + 'px';
        console.log("pdfContainer", pdfContainer);
        
        pdfContainer.appendChild(textLayerDiv);

        const textContent = await page.getTextContent();
        const renderTask = pdfjsLib.renderTextLayer({
          textContent: textContent,
          container: textLayerDiv,
          viewport: viewport,
          enhanceTextSelection: true,
          textDivs: [],
        });
        console.log("renderTask",renderTask);        

        console.log("textLayerDiv",textLayerDiv);     

        await renderTask.promise;

        
        const textDivs = textLayerDiv.querySelectorAll('span');
        console.log("textDivs",textDivs);        
        textDivs.forEach(div => {
          div.style.cursor = 'pointer'; 
          
          
          div.addEventListener('click', (event) => {
            const word = event.target.textContent; 
            console.log(word);            
            this.addMedicamento2(word); 
          });
        });

        renderTask.promise.then(() => {          
          const extractedText = Array.from(textLayerDiv.querySelectorAll("span"))
            .map((div) => div.textContent)
            .join(" ");
            console.log("Texto extraído:", extractedText);  
            this.procesarMedicamentos();        
            // this.sendToOpenAI(extractedText);
        });
      },      
      
      
      async loadPdf2() {
        const url = this.pdfUrl; 
        const pdfContainer = this.$refs.pdfContainer;
                
        const pdf = await pdfjsLib.getDocument(url).promise;
        
        const page = await pdf.getPage(1);
        const scale = 1.5;
        const viewport = page.getViewport({ scale });
        
        const textLayerDiv = document.createElement('div');
        textLayerDiv.className = 'textLayer';
        textLayerDiv.style.position = 'absolute';
        textLayerDiv.style.top = '0';
        textLayerDiv.style.left = '0';
        textLayerDiv.style.width = viewport.width + 'px';
        textLayerDiv.style.height = viewport.height + 'px';
        console.log("pdfContainer", pdfContainer);
        
        pdfContainer.appendChild(textLayerDiv);

        const textContent = await page.getTextContent();
        const renderTask = pdfjsLib.renderTextLayer({
          textContent: textContent,
          container: textLayerDiv,
          viewport: viewport,
          textDivs: [],
        });
        console.log("renderTask",renderTask);        

        console.log("textLayerDiv",textLayerDiv);     

        await renderTask.promise;

        
        const textDivs = textLayerDiv.querySelectorAll('span');
        console.log("textDivs",textDivs);        
        textDivs.forEach(div => {
          div.style.cursor = 'pointer'; 
          
          
          div.addEventListener('click', (event) => {
            const word = event.target.textContent; 
            console.log(word);            
            this.addMedicamento2(word); 
          });
        });
        renderTask.promise.then(() => {          
          const extractedText = Array.from(textLayerDiv.querySelectorAll("span"))
            .map((div) => div.textContent)
            .join(" ");
            console.log("Texto extraído:", extractedText); 
        });
      },      


      async loadPdf3() {
        const url = 'URL_DEL_PDF'; 
        const pdfContainer = this.$refs.pdfContainer;
                
        const pdf = await pdfjsLib.getDocument(url).promise;
        
        const page = await pdf.getPage(1);
        const viewport = page.getViewport({ scale: 1.5 });
        
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        pdfContainer.appendChild(canvas);
        
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        await page.render(renderContext).promise;
      },
      async fetchMedicamento(index, auto) {
        let data ={
          producto: this.obtenerPrimerNombreProducto(this.medicamentos[index].nombreProducto),
          laboratorio: this.obtenerPrimerNombreLaboratorio(this.laboratorio.nombre)
        }
        try {          
          this.$http
          .post("msa-administration/api/carta/medicamento", 
          data, { headers: { 'Accept': 'application/json' } })
          .then((result) => {
              console.log(result);
              console.log(result.data.body);
              if(result.data.body.length>1){
                if(!auto){
                  console.log(auto);
                  this.$set(this.medicamentos[index], 'resultados', result.data.body);
                }
              }else{
                this.selectMedicamento(index, result.data.body[0]);
              }
              // this.showSuccessMessage = true;

              // if (result.status==200) {
              //   this.$router.push({ path: '/modules/carta/'+data.data });  
              // } else {
              //   Swal.fire({
              //     title: 'Carta de agotado',
              //     text: "Error al subir el archivo",
              //     icon: 'error',
              //   })
              // }
          }) 
          .catch((error) => {
              console.log(error?.response?.data);
          }); 
          

          // let resultados=[];
          // [1,2,3,4,5].map((it)=>{
          //   resultados.push({
          //     id: it,
          //     nombre: medicamento +" - "+it, 
          //     dosis: it*5
          //   });
          // });
          // console.log(resultados);          

          
        } catch (error) {
          console.error('Error fetching medicamentos:', error);
        }
      },
      async procesarMedicamentos() {
        this.loading=true;
        for (let index = 0; index < this.medicamentos.length; index++) {
          try {
            await this.fetchMedicamento(index, true);
          } catch (error) {
            console.error(`Error al procesar el medicamento en la posición ${index}:`, error);
          }
        }
        this.loading=false;
      },
      selectMedicamento(index, medicamento) {
        this.medicamentos[index].nombreProducto= medicamento.nomProd;
        this.medicamentos[index].idProducto= medicamento.codProd;
        this.medicamentos[index].resultados= [];        
      },
      debounceFetchMedicamento(index) {        
        clearTimeout(this.debounceTimer);
        console.log(index);             
        this.debounceTimer = setTimeout(() => {
          console.log(index);                
          this.fetchMedicamento(index, false);
        }, 1500); 
      },
      debounceFetchMedicamento2(index) {   
        console.log(index);
        this.fetchMedicamento(index, false);
      },



      async fetchLaboratorio() {
        let data ={
          laboratorio: this.obtenerPrimerNombreLaboratorio(this.laboratorio.nombre)
        }
        try {          
          this.$http
          .post("msa-administration/api/carta/laboratorio", 
          data, { headers: { 'Accept': 'application/json' } })
          .then((result) => {
              console.log(result);
              console.log(result.data.body);
              if(result.data.body.length>1){
                  this.$set(this.laboratorio, 'resultados', result.data.body);
              }else{
                this.selectLaboratorio(result.data.body[0]);
              }
              // this.showSuccessMessage = true;

              // if (result.status==200) {
              //   this.$router.push({ path: '/modules/carta/'+data.data });  
              // } else {
              //   Swal.fire({
              //     title: 'Carta de agotado',
              //     text: "Error al subir el archivo",
              //     icon: 'error',
              //   })
              // }
          }) 
          .catch((error) => {
              console.log(error?.response?.data);
          }); 
          

          // let resultados=[];
          // [1,2,3,4,5].map((it)=>{
          //   resultados.push({
          //     id: it,
          //     nombre: medicamento +" - "+it, 
          //     dosis: it*5
          //   });
          // });
          // console.log(resultados);          

          
        } catch (error) {
          console.error('Error fetching medicamentos:', error);
        }
      },
      async procesarLaboratorio() {
        this.loading=true;
        await this.fetchLaboratorio(true);
        this.loading=false;
      },
      selectLaboratorio(laboratorio) {
        this.laboratorio.nombre= laboratorio.nomProd;
        this.laboratorio.id= laboratorio.codProd;
        this.laboratorio.resultados= [];        
      },
      debounceFetchLaboratorio() {        
        clearTimeout(this.debounceTimer);          
        this.debounceTimer = setTimeout(() => {              
          // this.fetchLaboratorio();
        }, 1500); 
      },
      debounceFetchLaboratorio2() {                     
        // this.fetchLaboratorio();
      },
      obtenerPrimerNombreLaboratorio(entidadFormulante) {
        entidadFormulante = entidadFormulante.trim();
        const regex = /\b(?:LABORATORIOS|LABORATORIO)\s+([A-Za-z]+)|\b([A-Za-z]+)/i;
        const match = entidadFormulante.match(regex);
        return match ? match[1] || match[2] : "";
      },
      obtenerPrimerNombreProducto(producto) {
        producto = producto.trim();
        const regex = /^([A-Za-z]+)/;
        const match = producto.match(regex);
        return match ? match[1] : "";
      },
      




      actualizarCarta() {
        this.medicamentos.map((it)=>{
          it.idProducto=(it.idProducto)?it.idProducto:0;
          it.fecha=(it.fecha)?it.fecha+"T05:00:00":null;
        });
        this.medicamentos.map(medicamento => ({
          estado: Number(medicamento.activo ?? 0)
        }));
        this.fecha= parseFecha(this.fecha)
        console.log(this.fecha);
        let data={
          estado: "2", 
          fechaEjecucion: new Date(this.fecha).toISOString().slice(0, 19),
          laboratorio: this.laboratorio.nombre,
          nombre: this.nombre, 
          medicamentos: this.medicamentos
        }
        console.log(data);
        this.$http
          .put("msa-administration/api/carta/editar/"+this.id, 
          data, { headers: { 'Accept': 'application/json' } })
          .then((result) => {
              console.log(result)
              this.showSuccessMessage = true;

              if (result.status==200) {
                // this.$router.push({ path: '/modules/carta/'+this.id });  
                // this.getPdf();
                this.estadoCarta=2;
                Swal.fire({
                  title: 'Carta de agotado',
                  text: "La carta ha sido actualizada",
                  icon: 'success',
                })
              } else {
                Swal.fire({
                  title: 'Carta de agotado',
                  text: "Error al subir el archivo",
                  icon: 'error',
                })
              }
          }) 
          .catch((error) => {
              console.log(error?.response?.data);
          }); 

          function parseFecha(fecha) {
            let partes = fecha.includes("/") ? fecha.split("/") : fecha.split("-");

            let dia = partes[0];
            let mes = partes[1];
            let año = "20" + partes[2]; 

            return `${año}-${mes}-${dia}`; 
          }
      },
      formatFecha(fecha) {
        const formatosPosibles = [
          'D [de] MMMM [de] YYYY',
          'DD [de] MMMM [de] YYYY',
          'D/MM/YYYY',
          'DD/MM/YYYY',
          'D-MM-YYYY',
          'DD-MM-YYYY',
          'MMMM D, YYYY',
          'YYYY-MM-DD',
          'YYYY/MM/DD',
        ];

        const fechaFormateada = moment(fecha, formatosPosibles, true);

        if (fechaFormateada.isValid()) {
          return fechaFormateada.format('YYYY-MM-DD');
        } else {
          console.error(`La fecha '${fecha}' no es válida o tiene un formato desconocido.`);
          return null;
        }
      },
      async sendToOpenAI(extractedText) {
        
        console.log(extractedText);

        if(this.dev){
          try {                              
            let response= this["response"+this.id];
            console.log(response);

            this.fecha= response.fecha;          
            this.laboratorio.nombre= response.entidad;
            
            response.medicamentos = response.medicamentos.map(med => ({
              ...med,
              fecha: med.fecha
            }));
            this.medicamentos=response.medicamentos;
            
            this.procesarMedicamentos();
          } catch (error) {
            console.error('Error al llamar a OpenAI:', error);
          }
        }else{
          console.log("OCR");
          const apiKey = 'sk-srmeUp5o-buGUn6r9msALApNit-hxs0-RSK-L5iQkDT3BlbkFJ5Mj4AOmZqa8mCsXr2j20D2Zi-FcsC0JOfIypV2at4A';  
          const url = 'https://api.openai.com/v1/chat/completions';

          const prompt = `
            A continuación te proporcionaré un texto extraído de un documento. 
            Necesito que identifiques la siguiente información y la devuelvas en formato JSON válido. 

            - El campo "fecha" debe extraer la fecha del documento, sin importar el formato en el que esté, y devolverla en el formato 'YYYY-MM-DD'.
            - El campo "entidad" debe contener el nombre de la entidad que genera el documento, si está presente.
            - En el array "medicamentos", debes listar los medicamentos con el formato:
              {
                "nombre": "El nombre completo del medicamento",
                "estado": 1 (si está disponible), 2 (si no está disponible), 3 (si está descontinuado),
                "fecha": "La fecha del medicamento si existe, en formato 'YYYY-MM-DD', si no poner la fecha de hoy en 'YYYY-MM-DD'"
              }

            Aquí está el texto extraído:

            ${extractedText}

            Por favor devuelve la respuesta únicamente en formato JSON.
          `;

          console.log(prompt);

          const result = await axios.post(
            url,
            {
              model: "gpt-3.5-turbo",  
              messages: [
                {
                  role: "user",
                  content: prompt
                }
              ]
            },
            {
              headers: {
                Authorization: `Bearer ${apiKey}`,
                'Content-Type': 'application/json'
              }
            }
          );
          console.log(result);
          let response = JSON.parse(result.data.choices[0].message.content);
          console.log(response);
          this.fecha= response.fecha;          
          this.laboratorio.nombre= response.entidad;
          
          response.medicamentos = response.medicamentos.map(med => ({
            ...med,
            fecha: med.fecha
          }));
          this.medicamentos=response.medicamentos;

          this.procesarMedicamentos();
        }
        
        
      },
      
      // Convierte el archivo a Base64
      handleFileUpload() {
        const file = this.selectedFile;
        if (!file || file.type !== 'application/pdf') {
          this.$refs.form.resetValidation();
          this.selectedFile = null;
          return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          this.base64File = e.target.result.split(',')[1]; // Solo el contenido en base64
        };
        reader.readAsDataURL(file);
      },

      
      async submitFile() {
        if (!this.base64File) return;
        console.log(this.base64File);        
        try {

          // this.$router.push({ path: '/modules/carta/1' }); 
          // window.location.reload();
          
          this.id=1; 
          this.loading=true;
          setTimeout(()=>{
            this.pdfUrl = `data:application/pdf;base64,${this.base64File}`;
            // this.loadPdf();
            this.embedPDF();
          },500);

          this.$http
          .post("msa-administration/api/carta/crear", 
          {
            archivoBase64: this.base64File,
            fecha: new Date().toISOString().slice(0, 19)
          }, { headers: { 'Accept': 'application/json' } })
          .then((result) => {
            console.log(result)
            
            this.loading=false;
            if (result.status==200) {
              let data= JSON.parse(result.data.body);
              console.log("data",data);
              let dataCarta= JSON.parse(data.data);
              console.log("dataCarta",dataCarta);
              // this.$router.push({ path: '/modules/carta/'+data.id });  
              this.id=data.id;
              // this.getPdf();
              

              this.fecha= (dataCarta.fecha)?dataCarta.fecha.replace(/\//g, "-"):"";
              this.laboratorio={
                nombre: dataCarta.entidad.toUpperCase(),
                nit: dataCarta.nitLaboratorio,
              }

              this.medicamentos = dataCarta.medicamentos.map(medicamento => ({
                ...medicamento,
                nombreProducto: medicamento.nombre,
                estado: Number(medicamento.activo ?? 0),
              }));

              this.procesarMedicamentos();

            } else {
              Swal.fire({
                title: 'Carta de agotado',
                text: "Error al subir el archivo",
                icon: 'error',
              })
            }
            // if (response.ok) {
            //   this.uploadStatus = true;
            //   this.selectedFile = null; 
            //   this.$refs.form.reset();  
            // } else {
            //   throw new Error("Error al subir el archivo");
            // }
          }) 
          .catch((error) => {
              console.log(error?.response?.data);
          });         

          
        } catch (error) {
          console.error(error);
          this.uploadStatus = false;
        }
      }
    }
  }
</script>
  
  <!-- #################################################################################### -->
  <!-- ###### Sección de style                                                       ###### -->
  <!-- #################################################################################### -->
  <style scoped>

  .pdf-container {
    width: 100%;
    height: 100vh; 
    overflow: auto; 
    position: relative;
  }
  
  .carta-file {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 98%;
  }
  
  .carta-file > div{
    width: 100%;
  }

  .pdf-word {
    font-size: 12px;
    position: absolute;
    white-space: nowrap; 
    pointer-events: none; 
  }

  ::v-deep .textLayer {
    position: absolute;
    top: 0;
    left: 0;
    color: transparent;
    font-family: sans-serif;
    cursor: pointer;
    opacity: 1;
  }

  ::v-deep .textLayer span {
    position: absolute;
    white-space: pre;
    transform-origin: 0% 0%;
    color: black !important;
  }

  .textLayer > span{
    color: black !important;

  }

  #pdf-container {
    width: 100%;
    height: auto;
    border: 1px solid black;
    position: relative;
  }

  .pdf-word {
    font-size: 12px;
    cursor: pointer;
  }

  .pdf-word:hover {
    background-color: yellow;
  }

  ul, ol {
    list-style: none;
  }
  
  .viewer-toolbar{
    display: none;
  }
  
  .divider {
    width: 10px;
    cursor: col-resize;
    background-color: #ccc;
    height: 100%;
  }
  
  article.carta {
    padding: 0;
    width: 100%;
  }
  
  article.carta .icons{
    display: flex;
    justify-content: flex-end;
    position: relative;
    /* position: absolute; */
    /* right: 20px; */
    /* top: 0; */
    width: 60px;
  }
  article.carta .icons figure{
    top: 11px;
    position: absolute;
  }
  
  article.carta .ico-check{
    font-size: 1.2rem;
    padding: .2rem;
    width: 30px;
    height: 30px;
    
  }
  
  article.carta .ico-check i{
    border-radius: 50%;
    border: 1px solid green;
    color: green;
    padding: .2rem;
  }
  
  article.carta .ico-warning{
    font-size: 1.2rem;
    padding: .2rem;
    width: 30px;
    height: 30px;
    
  }
  
  article.carta .ico-warning i{
    border-radius: 50%;
    border: 1px solid orange;
    color: orange;
    padding: .2rem;
  }
  
  article.carta .ico-error{
    font-size: 1.2rem;
    padding: .2rem;
    width: 30px;
    height: 30px;
    
  }
  
  article.carta .ico-error i{
    border-radius: 50%;
    border: 1px solid red;
    color: red;
    padding: .2rem;
  }
  
  article.carta .ico-more{
    font-size: 1.2rem;
    padding: .2rem;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  article.carta .ico-more i{
    border-radius: 50%;
    border: 1px solid var(--v-primary-base);
    color: var(--v-primary-base);
    padding: .2rem;
  }
  
  
  #viewer #toolbar{
    display: none !important;
  }
  
  article.carta .viewer-toggle {
    position: absolute;
    top: 10px;
    right: 50px;
    width: 30px;
    height: 30px;
    display: flex;
  }

  article.carta .viewer-toggle i{
    cursor: pointer;
    margin: 0 5px;
  }

  article.carta .hide{
    display: none;
  }

  article.carta .viewer-toggle .active i{
    color: #0D47A1;
  }

  article.carta > section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin: 0;
    height: calc(100vh - 69px);
    
  }
  
  
  article.carta > section > aside {
    width: 100%;
    height: calc(100vh - 69px);
    overflow-y: auto;
    position: relative;
  }
  
  ::v-deep .v-application--is-ltr .v-input__prepend-outer{
    display: none !important;
  }

  article.carta > section > aside  > h3{
    margin: 10px;
    height: 20px;
  }
  
  article.carta > section > aside > div {
    width: 100%;
    height: calc(100% - 40px);
    position: relative;
    overflow-y: auto;
  }
  
  article.carta > section > aside > div > article {
    margin-bottom: .4rem;
  }
  
  article.carta > section > aside > div > article h4{
    padding-left: 5px;
    max-width: fit-content;
  }
  
  article.carta > section > aside > div > article > ul {
    padding-left: 5px;
    
  }
  
  article.carta > section > aside > div > article > ul > li {
    display: flex;
    justify-content: center;
    position: relative;
  }
  
  article.carta > section > aside > div > article.paciente > ul > li > div:first-child ,
  article.carta > section > aside > div > article.medico > ul > li > div:first-child {
    width: 80px;
    min-width: 80px;
  }
  article.carta > section > aside > div > article.medico > ul > li > div:nth-child(2),
  article.carta > section > aside > div > article.paciente > ul > li > div:nth-child(2){
    width: 20%
  }
  article.carta > section > aside > div > article.paciente > ul > li > div:nth-child(3){
    width: 60%
  }
  article.carta > section > aside > div > article.medico > ul > li > div:nth-child(3){
    width: 80%
  }
  article.carta > section > aside > div > article.paciente > ul > li > div:nth-child(4){
    width: 20%
  }
  
  article.carta > section > aside > div > article.diagnostico > ul > li > div:nth-child(1){
    width: 20%
  }
  article.carta > section > aside > div > article.diagnostico > ul > li > div:nth-child(2){
    width: 80%
  }
  
  article.carta > section > aside > div > article.medicamentos > ul > li > div:nth-child(1){
    width: 60%;
    position: relative;
  }
  article.carta > section > aside > div > article.medicamentos > ul > li > div:nth-child(2){
    width: 15%;
    min-width: 150px;
  }
  article.carta > section > aside > div > article.medicamentos > ul > li > div:nth-child(3){
    width: 20%;
    min-width: 85px;
  }
  article.carta > section > aside > div > article.medicamentos > ul > li > div:nth-child(4){
    width: 5%
  }
  article.carta > section > aside > div > article.medicamentos > ul > li > div:nth-child(4) figure{
    cursor: pointer;
  }
  article.carta > section > aside > div > article.medicamentos > ul > li > div:nth-child(4) figure i{
    color: rgba(176, 0, 32);
  }
  
  article.carta > section > aside > div > article.medicamentos i{
    margin: 0 !important;
  }
  article.carta > section > aside > div > article.diagnostico{
    padding-left: 15px;
    margin-top: 20px;
  }
  
  article.carta > section > aside > div > article.diagnostico .icons figure{  
  }

  article.carta > section > aside > div > article.medicamentos > ul > li .medicamento-check{
    position: absolute;
    left: 45px;
    top: -2px;
    display: flex;
  }
  
  article.carta > section > aside > div > article.medicamentos > ul > li .medicamento-check i{
    color: green;
    font-size: 15px;
  }
  article.carta > section > aside > div > article.medicamentos > ul > li .medicamento-check p{
    color: green;
    font-size: 13px;
    margin: 0;
    margin-left: 4px;
  }
  article.carta > section > aside > div > article.medicamentos > ul > li .medicamento-alert{
    position: absolute;
    left: 45px;
    top: -4px;
  }
  
  article.carta > section > aside > div > article.medicamentos > ul > li .medicamento-alert i{
    color: red;
    font-size: 15px;
  }
  
  
  article.carta > section > aside > div > article.medicamentos{
    padding-left: 15px;
    margin: 20px 0;
  }
  article.carta > section > aside > div > article.fecha > ul > li > div {
    padding-left: 15px;
  }
  article.carta > section > aside > div > article.entidad > ul > li > div {
    padding-left: 15px;
  }
  article.carta > section > aside > div > article.entidad {
    position: relative;
  }
  article.carta > section > aside > div > article.entidad .laboratorio-check {
    position: absolute;
    left: 45px;
    top: -2px;
    display: flex;
  }
  article.carta > section > aside > div > article.entidad .laboratorio-alert {
    position: absolute;
    left: 90px;
    top: -4px;
  }
  article.carta > section > aside > div > article.entidad .laboratorio-alert i{
    color: red;
    font-size: 15px;
  }
  
  article.carta > section > aside > div > article > ul > li > div {
    margin: 0 .5rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }
  article.carta > section > aside > div > article > ul > li > div > div:not(.icons){
    width: 100%;
  }
  
  article.carta > section > aside > div footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-top: 1rem;
    margin-right: 10px;
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
  
  article.carta > section > aside > div footer button{
    margin: 0 .2rem;
  }
  
  article.carta > section > article {
    margin-left: 0;
    width: 100%;
    height: calc(100vh - 69px);
  }
  
  article.carta > section > article h4 {
    margin-bottom: 1rem;
  }
  
  article.carta > section > article section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 0.05rem solid #0d47a1;
    height: 100%;
    width: 100%;
  }
  
  article.carta > section > article section > div:first-child{    
  }
  
  article.carta > section > article section > div,
  article.carta > section > article section > div .pdfobject-container {
    width: 100%;
    height: 100%;
  }
  
  article.carta > section > article section div h6 {
    font-size: 2rem;
    text-align: center;
  }
  
  article.carta > section > article section div figure svg {
    font-size: 4rem;
  }

  article.carta > section > article{
    position: relative;
  }

  #pdf-viewer-cover{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  article.carta > section > article footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 1rem;
    margin-right: 30px;
  }
  
  .loading{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(100vh - 53px);
    background-color: rgba(0,0,0,.5);
    z-index: 2;
  }

  .loading h4{
    width: 100%;
    text-align: center;
    color: white;
    font-size: 19px;
    margin-top: -20px;
    font-weight: 200;
  }
  
  @keyframes ldio-yzaezf3dcmj {
    0% { opacity: 1 }
    100% { opacity: 0 }
  }
  .ldio-yzaezf3dcmj div {
    left: 96px;
    top: 45px;
    position: absolute;
    animation: ldio-yzaezf3dcmj linear 1s infinite;
    background: #0d47a1;
    width: 8px;
    height: 18px;
    border-radius: 3.42px / 3.42px;
    transform-origin: 4px 55px;
  }.ldio-yzaezf3dcmj div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.9375s;
    background: #0d47a1;
  }.ldio-yzaezf3dcmj div:nth-child(2) {
    transform: rotate(22.5deg);
    animation-delay: -0.875s;
    background: #0d47a1;
  }.ldio-yzaezf3dcmj div:nth-child(3) {
    transform: rotate(45deg);
    animation-delay: -0.8125s;
    background: #0d47a1;
  }.ldio-yzaezf3dcmj div:nth-child(4) {
    transform: rotate(67.5deg);
    animation-delay: -0.75s;
    background: #0d47a1;
  }.ldio-yzaezf3dcmj div:nth-child(5) {
    transform: rotate(90deg);
    animation-delay: -0.6875s;
    background: #0d47a1;
  }.ldio-yzaezf3dcmj div:nth-child(6) {
    transform: rotate(112.5deg);
    animation-delay: -0.625s;
    background: #0d47a1;
  }.ldio-yzaezf3dcmj div:nth-child(7) {
    transform: rotate(135deg);
    animation-delay: -0.5625s;
    background: #0d47a1;
  }.ldio-yzaezf3dcmj div:nth-child(8) {
    transform: rotate(157.5deg);
    animation-delay: -0.5s;
    background: #0d47a1;
  }.ldio-yzaezf3dcmj div:nth-child(9) {
    transform: rotate(180deg);
    animation-delay: -0.4375s;
    background: #0d47a1;
  }.ldio-yzaezf3dcmj div:nth-child(10) {
    transform: rotate(202.5deg);
    animation-delay: -0.375s;
    background: #0d47a1;
  }.ldio-yzaezf3dcmj div:nth-child(11) {
    transform: rotate(225deg);
    animation-delay: -0.3125s;
    background: #0d47a1;
  }.ldio-yzaezf3dcmj div:nth-child(12) {
    transform: rotate(247.5deg);
    animation-delay: -0.25s;
    background: #0d47a1;
  }.ldio-yzaezf3dcmj div:nth-child(13) {
    transform: rotate(270deg);
    animation-delay: -0.1875s;
    background: #0d47a1;
  }.ldio-yzaezf3dcmj div:nth-child(14) {
    transform: rotate(292.5deg);
    animation-delay: -0.125s;
    background: #0d47a1;
  }.ldio-yzaezf3dcmj div:nth-child(15) {
    transform: rotate(315deg);
    animation-delay: -0.0625s;
    background: #0d47a1;
  }.ldio-yzaezf3dcmj div:nth-child(16) {
    transform: rotate(337.5deg);
    animation-delay: 0s;
    background: #0d47a1;
  }
  .loadingio-spinner-spinner-2by998twmg8 {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
  }
  .ldio-yzaezf3dcmj {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-yzaezf3dcmj div { box-sizing: content-box; }

  .medicamento-nombre{
    position: relative;
  }
  
  .medicamento-nombre > section{
    position: absolute;
    width: 100%;
    top: 48px;
    z-index: 1;
  }
  
  .medicamento-nombre > section > ul{
    width: 100%;    
    min-height: 20px;
    border: 1px solid gray;
    background-color: white;
    padding-left: 0;
    border-radius: 2px;
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
  }
  
  .medicamento-nombre > section > ul li{
    display: flex;
    cursor: pointer;
    margin: 2px 0
  }
  
  .medicamento-nombre > section > ul li h4{
    margin-right: 10px; 
    font-size: 13px;   
  }
  
  .medicamento-nombre > section > ul li h5{
    margin-right: 10px;
    font-size: 13px;  
    white-space: nowrap; 
  }

</style>